import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppDispatch } from '@Redux';
import Modal, { FooterBackground, ModalSize } from '@isp/modal';
import { PushDataToTagManagerForButtons } from '@Helpers/google-analytics/pushDataToTagManager';
import { TOGGLE_PARTS_VALIDATION_INTEGRATION_MODAL } from '@Reducers/modals/actions';
import Loading, { LoadingSizes } from '@isp/loading';
import TristateSelection, { TristateSelectionStates } from '@isp/tristate-selection';
import ButtonWithIcon from '@isp/button-with-icon';
import { IconType } from '@isp/icon';

const PartsValidationIntegrationModal = (): JSX.Element => {
	const { t } = useTranslation(['shop', 'common']);
	const dispatch: AppDispatch = useDispatch();

	const onClose = () => dispatch(TOGGLE_PARTS_VALIDATION_INTEGRATION_MODAL());
	const handleClose = e => {
		PushDataToTagManagerForButtons(e.target.id, window.location.href);
		onClose();
	};

	return (
		<>
			<Modal
				show
				size={ModalSize.LG}
				tagTooltipContent="Tag tooltip"
				footerEditBgColor={FooterBackground.WHITE}
				footerwithMiddleContent
				footerMiddleContent={
					<div className="train__justify-center train__center">
						<Loading size={LoadingSizes.TINY} className="m-r-2" />
						<p>Validating parts (21%)</p>
					</div>
				}
				modalWithTag
				tagContent="Beta"
				isPrimaryButtonVisible
				primaryButtonText={t('common:cancel')}
				primaryButtonAction={e => handleClose(e)}
				// primaryButtonId={}
				secondaryButtonText={
					<ButtonWithIcon
						// disabled={}
						iconType={IconType.BASKET}
						className=""
						onClick={() => {}}
						// id={}
					>
						Add items (10/20)
					</ButtonWithIcon>
				}
				// secondaryButtonAction={}
				// secondaryButtonId={}
			>
				<div>
					<h2>{t('shop:parts-integration-modal-header')}</h2>
					<div className="train train__flex m-t-3">
						<p className="fs-16">{t('shop:parts-integration-modal-description')}</p>
						<div className="disp-flex" style={{ marginLeft: 'auto', marginRight: '50px' }}>
							<p className="DaimlerCS-Bold fs-16 m-r-2">{t('shop:hide-valid-items')}</p>
							<TristateSelection
								className=""
								value={TristateSelectionStates.EMPTY}
								onChange={() => {}}
								id={''}
								variant="primary"
							/>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default PartsValidationIntegrationModal;
