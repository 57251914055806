import { get } from 'lodash';
import serviceEndpoints from '@Service-endpoints';
import getAppEnvironment from '@Helpers/common/getAppEnvironment';

export const getWebpartsDocumentUrl = (url: string) => {
	const tokens = url?.split('/');
	const baseURL = `${get(serviceEndpoints.config[getAppEnvironment()], 'webpartsDocumentUrl')}`;

	return `${baseURL}${tokens[tokens.length - 1]}`;
};
